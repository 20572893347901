<template>
    <div>
        <div class="pc-container">
            <div class="text-content" style="background: #AC8250;">
                <div class="text-h4 white--text text-center font-weight-bold mb-15">{{ $t('Page.Govern.Title') }}</div>
                <img src="@/assets/images/govern.png" style="width:60%" class="d-none d-sm-block mx-auto" />
                <img src="@/assets/images/govern.png" style="width:100%" class="d-block d-sm-none mx-auto" />
                <div class="white--text mt-15" >
                    <div>
                        <div class="mb-5">● {{ $t('Page.Govern.SubTitle1') }}</div>
                        <div>
                            <p>
                                {{ $t('Page.Govern.node1') }}
                            </p>
                            <p>
                                {{ $t('Page.Govern.NodeDesc1') }}。
                            </p>

                            <p>{{ $t('Page.Govern.node2') }}</p>
                            <p>
                                {{ $t('Page.Govern.NodeDesc2') }}。
                            </p>

                            <p>{{ $t('Page.Govern.node3') }}</p>
                            <p>
                                {{ $t('Page.Govern.NodeDesc3') }}。
                            </p>
                        </div>
                    </div>

                    <div class="mt-10">
                        <div class="mb-5">● {{ $t('Page.Govern.SubTitle2') }}</div>
                        <div>
                            {{ $t('Page.Govern.SutTitle2Desc') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-content" style="background: #FFF8F8">
                <div class="text-h5 font-weight-bold text-center">{{ $t('AppBar.SubMenu.Partner') }}</div>
                <v-card elevation="0" color="#FFFCFA" class="mt-8 py-13 px-15">
                <img width="100%" height="auto" src="@/assets/images/partner.png" class="d-block" />
                </v-card>
            </div>

           

            <div></div>
           
            <Footer></Footer>
        </div>
    </div>
  </template>
  
  <script>
  import Footer from '../../components/Footer.vue'
  
  
  export default {
    name: 'Govern',
    components: {
      Footer
    },
    mounted(){
    }
  }
  </script>
  
  <style lang="scss">

    .text-content {
        padding: 90px 120px
    }
  
    @media screen and (max-width: 960px) {
      .mobile-container {
        overflow: hidden;
      }

      .text-content {
        padding: 30px 24px;
      }
    }
  </style>
  